.deals-page {
    .banner {
        height: 300px;
        background-image: url(https://assets.WBroomscdn.com//payday_Deallanding_page_360x272.jpg);
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .deals-block {
        display: flex;
        flex-direction: column;
        width: 85%;
        margin: 20px auto;
        text-align: center;
        padding: 20px 80px;
        position: relative;
        top: -200px;

        background: #fff;
        border-radius: 2px;
        // padding: 16px 0 65px;
        box-shadow: 0 12px 24px 4px rgb(0 0 0 / 12%), 0 8px 16px -8px rgb(0 0 0 / 22%);

        .coupon {
            margin: 12px auto 19px;
            height: 68px;
            border-radius: 100px;
            font-size: 20px;
            border: 2px solid #1ab64f;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #1ab64f;
            padding: 0 30px;

            .coupon-container {
                .coupon-wrap>svg {
                    vertical-align: middle;
                }
            }
        }

        .offerend {
            font-size: 12px;
            color: #222;
            font-weight: 600;
        }

        .description {
            .desc {
                font-size: 26px;
                color: rgba(0, 0, 0, .7);
                padding: 0 30px;
                color: #333;
                margin: 24px 0 12px;
                font-weight: 700;
                line-height: 1.3;
            }
        }
    }
}