.navbar {
  height: auto;
  background-color: $color-background-1;
  // z-index: 998;
  // box-shadow: $shadow-1;

  .top {
    padding: 0 0 0 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      width: 10rem;
      img {
        width: 100%;
        cursor: pointer;
      }
    }

    .right {
      display: flex;
      color: $color-grey-6;

      .card {
        height: 7.5rem;
        padding: 0 3rem;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: rgba($color-grey-2, 0.5);
        }

        &:not(:last-child) {
          border-right: 1px solid rgba($color-grey-2, 0.5);
        }

        .icon {
          margin-right: 1rem;
          svg {
            font-size: 3.5rem;
          }
        }

        .details {
          h3 {
            margin-bottom: 0.25rem;
          }
          p {
            font-size: 1.1rem;
          }
        }
      }
    }
  }

  .bottom {
    // height: 5rem;
    background-color: rgba($color-grey-1, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5rem;
    flex-wrap: wrap;

    .item-wrapper {
      position: relative;
      .item {
        display: flex;
        align-items: center;
        height: 5rem;
        padding: 0 1.5rem;
        cursor: pointer;

        p {
          margin-right: 0.5rem;
        }
        svg {
          font-size: 3rem;
          transition: $transition-1;
        }
      }
      .item.all {
        color: $color-primary-2;

        svg {
          font-size: 2rem;
        }
      }

      .menu {
        display: none; // by asad
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 20rem;
        width: fit-content;
        box-shadow: $shadow-1;
        border-radius: 0 0 5px 5px;
        overflow-y: scroll;
        max-height: 20rem;
        object-fit: cover;

        // visibility: hidden; // original
        // opacity: 0;
        background-color: $color-light;
        z-index: 998;

        h3 {
          padding: 2rem;
        }
        .menu-item {
          padding: 1rem 2rem;
          cursor: pointer;
          &:hover {
            background-color: rgba($color-grey-2, 0.5);
          }
        }

        .menu-item.all {
          color: $color-primary-2;
          display: flex;
          align-items: center;
          p {
            margin-right: 0.5rem;
          }
          svg {
            font-size: 2rem;
          }
        }
      }

      &:hover {
        .item {
          background-color: rgba($color-grey-2, 0.5);
          background-color: $color-background-1;

          svg {
            transform: rotate(180deg);
            transform-origin: center;
          }
        }

        .item.all {
          svg {
            transform: translateX(0.5rem);
          }
        }
        .menu {
          // visibility: visible; // original
          display: block; // by asad
          opacity: 1;
        }
      }
    }
  }
  .menu-item {
    padding: 1rem 2rem;
    cursor: pointer;
    &:hover {
      background-color: rgba($color-grey-2, 0.5);
    }
  }

  // this is coded by asad(updated scroll bar css)
  ::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b3b3b3; /* Set the color of the scrollbar thumb */
    border-radius: 6px; /* Round the corners of the scrollbar thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #4d4d4d; /* Set the color of the scrollbar thumb on hover */
  }
  // code ends here by asad

  .menu-item.all {
    color: $color-primary-2;
    display: flex;
    align-items: center;
    p {
      margin-right: 0.5rem;
    }
    svg {
      font-size: 2rem;
    }
  }
}

@include respond(tab-port) {
  .navbar {
    display: none;
    height: fit-content;
    .top {
      padding: 0;
      flex-direction: column;
      h1 {
        padding: 2rem 0;
      }
      .right {
        .card {
          padding: 0 1rem;
          .icon {
            // display: none;
            svg {
              font-size: 2rem;
            }
          }
          .details {
            p {
              display: none;
            }
          }
        }
      }
    }


    


    .bottom {
      padding: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      height: fit-content;

      .item-wrapper {
        .item {
          // justify-content: center;
        }
      }
    }
  }
}
