.footer {
  background-color: $color-grey-6;
  color: $color-light;

  .sec1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
    border-bottom: 1px solid rgba($color-grey-1, 0.5);

    .left {
      .action {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        h1 {
          margin-right: 5rem;
        }
      }
    }
    .right {
      .desc {
        margin-bottom: 2rem;
      }
      .badges {
        display: flex;
        align-items: center;

        .app-store-badge {
          width: 13.5rem;
          margin-right: 5rem;
          cursor: pointer;
          img {
            width: 100%;
            object-fit: contain;
          }
        }

        .google-play-badge {
          width: 15rem;
          cursor: pointer;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .sec2 {
    padding: 1.5rem 3rem;
    margin-bottom: -20px;
    border-bottom: 1px solid rgba($color-grey-1, 0.5);

    h3 {
      margin-bottom: 2rem;
    }

    .flex {
      display: flex;

      .col {
        flex: 1;
        a {
          display: block;
          width: fit-content;
          margin-bottom: 1rem;
          cursor: pointer;
          font-size: 1.4rem;
          font-weight: bold;
          color: $color-grey-3;

          &:hover {
            color: $color-light;
          }
        }
      }
    }
  }

  .sec3 {
    padding: 3rem 5rem;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      .desc {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        .logo {
          margin-right: 5rem;
          width: 10rem;
          img {
            width: 100%;
          }
        }

        h3 {
          font-size: 1.5rem;
        }
      }

      .icons {
        display: flex;
        align-items: center;

        .icon {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          padding: 2rem;
          margin: 0.5rem;
          background-color: $color-grey-3;
          @include flexCenter;
          cursor: pointer;

          &:hover {
            background-color: $color-light;
          }

          &:not(:last-child) {
            margin-right: 2rem;
          }
          svg {
            font-size: 2rem;
          }
          &:nth-child(1) {
            color: #c94e61;
          }
          &:nth-child(2) {
            color: #38529a;
          }
          &:nth-child(3) {
            color: #1c9cea;
          }
          &:nth-child(4) {
            color: #f70000;
          }
        }
      }
    }

    .bottom {
      text-align: center;
      .copyright {
        font-size: 1.2rem;
        color: $color-grey-3;
      }
    }
  }
  .sec4 {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .container-footer-sec {
      width: 90%;
      margin: auto;

    }
  }
}

@include respond(tab-port) {
  .footer {
    .sec1 {
      flex-direction: column;

      .left {
        margin-bottom: 2rem;
      }
    }

    .rem{
      display: none;
    .sec3 {
      .top {
        flex-direction: column;

        .desc {
          margin-bottom: 2rem;
        }
      }
    }
  }
    // .desc {
    //   flex-direction: column;
    // }
  }
}
