.navbar-2 {
  position: fixed;
  top: -8rem;
  left: 0;
  right: 0;
  min-height: 7.5rem;
  padding: 0 5px;
  background-color: $color-background-1;
  box-shadow: $shadow-1;
  z-index: 999;
  display: grid;
  align-items: center;
  transition: $transition-1;
  grid-template-areas: "logo search card"
                        "logo search card";

  .logo {
    grid-area: logo;
    margin: 0 1rem;
    width: 10rem;
    cursor: pointer;
    img {
      width: 100%;
    }
  }

  .search-hotels {
    grid-area: search;
    flex: 1;
    width: 100%;
    border-radius: 5px;
    margin-right: 30rem;

    .input {
      height: 5rem;
    }
  }

  .card {
    height: 7.5rem;
    grid-area: card;
    padding: 0 3rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: rgba($color-grey-2, 0.5);
    }

    &:not(:last-child) {
      border-right: 1px solid rgba($color-grey-2, 0.5);
    }

    .icon {
      margin-right: 1rem;
      svg {
        font-size: 3.5rem;
      }
    }

    .details {
      h3 {
        margin-bottom: 0.25rem;
      }
      p {
        font-size: 1.1rem;
      }
    }
  }
}

.navbar-2.navbar-active {
  top: 0;
}
.navbar-2.navbar-active-tab {

  .search-hotels{
    display: none;
  }
}
.navbar-2.navbar-active.navbar-active-desktop {
  position: sticky;
  top: 0rem;
}

@include respond(tab-port) {
  .navbar-2 {
    grid-template-areas: 
    "logo . card"
    "search search search";
    .search-hotels{
      margin-bottom: 1rem;
      margin-right: 0rem;
    }
  }
}
