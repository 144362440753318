.search-results {
  height: 100%;
  // padding-top: 7.5rem;
  display: flex;
  .left {
    flex: 1;
    border-right: 1px solid rgba($color-grey-3, 0.5);
    position: sticky;
    top: 7rem;
    align-self: flex-start;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(203, 213, 225, 0.5);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(203, 213, 225, 0.8);
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid rgba($color-grey-3, 0.5);

      .btn {
        padding: 1rem 2rem;
        border-radius: 20px;
        background-color: #0d9488;
        user-select: none;
        transition: $transition-1;
        cursor: pointer;
        &:hover {
          background-color: $color-primary-1;
        }
      }
    }

    .price {
      padding: 2rem;
      border-bottom: 1px solid rgba($color-grey-3, 0.5);
      h3 {
        margin-bottom: 1rem;
      }
      .range {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
      }
    }

    .filters {
      padding: 2rem;
      border-bottom: 1px solid rgba($color-grey-3, 0.5);
      h3 {
        margin-bottom: 1rem;
      }

      .filter {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        p {
          margin-left: 1rem;
        }
      }
    }
  }

  .right {
    flex: 4;
    padding: 3rem;
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3rem;

      .map-switch {
        display: flex;
        align-items: center;
        p {
          margin-right: 1rem;
        }
      }

      .sort-select {
        min-width: 20rem;
      }
    }
    .coupon {
      padding: 1rem;
      max-width: 50rem;
      display: flex;
      align-items: center;
      border: 2px solid rgba($color-grey-3, 0.5);
      border-left: 5px solid $color-success-2;
      background-color: rgba($color-grey-3, 0.2);
      border-radius: 10px;
      cursor: pointer;
      margin-bottom: 3rem;
      transition: $transition-1;

      .icon {
        margin-right: 2rem;
        svg {
          font-size: 3rem;
          color: $color-success-2;
        }
      }
      .desc {
        h3 {
          margin-bottom: 0.5rem;
          span {
            color: $color-success-2;
          }
        }
        p {
          font-size: 1.2rem;
          color: $color-grey-5;
        }
      }
    }
    @import "./uis/SearchResultsCard/card";
  }
}
@media only screen and (min-width: 824px) {
  .filter-left {
    height: 100vh; /* Adjust the height as needed */
    overflow-y: auto;
  }
}
@media only screen and (max-width: 524px) {
  .search-results {
    flex-wrap: wrap;
  }
}
