$small: 750px;
.profile-page {
    *{
        font-family: $font-primary;
        color: rgba(51,51,51,1);
    }

    div{
        border-radius: 5px;
    }
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 2rem auto 2rem; 
    .container{
        display: flex;
        flex-wrap: wrap;
    }
    .left-card, .right-card {
        margin: 2rem 2rem;
        width: 380px;
        height: 400px;
        padding: 0rem 3rem;
        border-radius: 2px;
        flex: 0 1 auto;
    }
    .edit{
        padding: 7rem;
        font-size: 16px;
        font-weight:bold;
    }
    .form-edit > *{
        margin: 1rem 0;
    }
    .shadow{
        box-shadow: 2px 4px 8px 0 rgb(0 0 0 / 10%);
        border: solid 1px #d6d6d6;
    }
    .left-card  > * {
        margin: 1rem 0;
    }
    .right-card  > * {
        margin: 1rem 0;
    }
    .svg-container{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: rgb(0, 0, 0);
        border-radius: 50%;
        width: 42px;
        height: 42px;
        margin-top: 2rem;
    }
    .button-holder{
        width: 75%;
    }
    .user-balance-info{
        font-size: 16px;
        line-height: 1.56;
    }
    .history-card-container{
        margin: 2rem 2rem;
        border: solid 1px #d6d6d6;
        border-radius: 2px;
        padding: 2rem;
    }
    .history-card-toolbar{
        display: flex;
        justify-content: space-between;
        padding: 2rem;
    }
    .history-card-toolbar{
        margin: auto 2rem;
    }
    .booking-history-container{
        display: flex;
        flex-direction: column;
        padding: 2rem;
    }
    .booking-history{
        display: flex;
        flex-wrap: wrap;
        margin: auto 2rem;
        align-items: center;
        padding: 2rem;
        overflow: auto;
        gap: 2rem;
    }
    .booking-history > *{
        flex: 1 1 auto;
        margin: 0 1rem;
        
    }
    .edit-card-container{
        display: flex;
        flex-wrap: wrap;
    }
    .edit-card-container > *{
        flex: 1 1 auto;
    }
    p{
        font-size: 16px;
        line-height: 1.56;
    }
    input{
        border: hidden;
        height:3rem;
        width: 80%;
        padding: 5px;
    }
    .left > img{
        width: 140;
        margin-right: 1rem;
    }
    .image{
        width: 140px;
        margin-right: 1rem;
    }

}

@media (max-width: $small) {
    .profile-page{            
        .left-card, .right-card{
            flex: 1 1 100%;
        }
        width: 90%;
        .image{
            width: 100%;
        }
        .left-card,.right-card{
            flex-grow: 1;
        }
    }    
}

