.dabba{
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    width: 200px;
    height: 140px;
    position: absolute;
    top: 20%;
    left: 86%;
    background-color: white;
}

.display-dabba{
    display: none;
}
.hide-dabba {
    display: block;
}