/* author:poojamai:02-06-2023: start */
/* .img-container {
    width: 100%;
    height: 220px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .img-container img {
    margin: auto;
    width: 90%;
    height: 100%;
    top: 0%;
    transform: translate(0%, 0%);
    border-radius: 4px;
  } */

/* author:poojamai:02-06-2023: end */

/* @media screen and (max-width: 768px) {
  .img-container {
    height: 60px;
  }
} */
.img-container {
  position: relative;
  width: 100%;
  height: 220px; /* Adjust height as needed */
  overflow: hidden;
}

.img-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.img-slide.active {
  opacity: 1;
}

@keyframes slide {
  0% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.img-slide {
  animation: slide 8s infinite;
}

.img-slide:nth-child(1) {
  animation-delay: 0s;
}

.img-slide:nth-child(2) {
  animation-delay: 4s;
}

 @media screen and (max-width: 768px) {
  .img-container {
    height: 80px;
  }
} 