.coupons-add-in-price {
    color:  #2e7d32;
    cursor: pointer;
}
.coupon-card-single {
    display: flex;
    justify-content: space-between;
    margin: 8px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 100%;
    padding: 10px;
   
}
.coupon-card-single:hover {
    background-color: #E6F3F6;
    transition: 0.5s ease-in;
}