/* .banner {
  margin-left: 180px;
} */
@media (max-width: 768px) {
  .banner {
    margin-left: 20px;
    width: 100%;
  }
  .content-tc {
    margin-left: -300px;
    width: 100%;
  }
}
