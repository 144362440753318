#google-map-demo{
    font-weight: bold;
    font-size: 16px;
}

/* // this is coded by asad(updated scroll bar css) */
      ::-webkit-scrollbar {
        width: 6px; /* Set the width of the scrollbar */
      }

      ::-webkit-scrollbar-thumb {
        background-color: #b3b3b3; /* Set the color of the scrollbar thumb */
        border-radius: 6px; /* Round the corners of the scrollbar thumb */
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #4d4d4d; /* Set the color of the scrollbar thumb on hover */
      }
      /* // code ends here by asad */