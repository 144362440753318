* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Optima, sans-serif;
}
/* div1 start from here */
.career-banner {
  background-image: url(../../media/images/Careers.jpeg);
  height: 80vh;
  background-size: cover;
}

.newlook-caption-title {
  display: flex;
  height: inherit;
  justify-content: center;
  align-items: center;
}

.newlook-caption-title h1 {
  font-family: Optima, sans-serif;
  font-weight: 600;
  font-size: 50px;
  line-height: 82px;
  white-space: normal;
  padding-bottom: 15px;
  text-shadow: 0 2px 4px rgba(36, 33, 33, 0.7);
  color: #fff;
  text-align: center;
  margin-top: 80px;
  /* border: 2px solid red; */
}

/* div1 end here */

/* div2 start from here */
.newlook-footerPage-nav {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e7e7e7;
  text-align: center;
}

.newlook-footerPage-nav-container {
  display: inline-block;
}

.newlook-footerPage-nav-item {
  display: inline-block;
  padding: 0 9px;
  line-height: 63px;
  position: relative;
  overflow: hidden;
}

.newlook-footerPage-nav-item a {
  color: #4e4e4e;
  display: inline-block;
  padding-bottom: 1px;
  font-size: 15px;
  line-height: 17px;
  font-weight: 600;
}
.newlook-footerPage-nav-item.checked a,
.newlook-footerPage-nav-item:hover a {
  color: #9f7d23;
  border-bottom: 1px solid #9f7d23;
}

/* div2 end here */

/* div3 start from here */
.newlook-memberrewards-content-main {
  background-color: #f7f4ee;
}

.newlook-memberrewards-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 90%;
  margin: auto;
  gap: 20px;
  margin-top: 80px;
}
.rich-text-content {
  width: 90%;
  margin: auto;
  text-align: justify;
}

.rich-text-content h4 {
  font-family: Optima, sans-serif;
  font-weight: 600;
  color: #4e4e4e;
  font-size: 28px;
  line-height: 32px;
  padding-bottom: 20px;
  /* margin-top: 40px; */
  padding-top: 30px;
}

.rich-text-content p strong {
  font-weight: 700;
  line-height: 25px;
}

.newlook-memberrewards-image {
  background-image: url(../../media/images/Career-at-wbhotels-and-resorts.jpg);
  width: 80%;
  margin: auto;
}

.newlook-memberrewards-image {
  width: 100%;
  height: 0;
  padding-top: 74%;
  position: relative;
  top: -4rem;
  background-color: #363636;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* border: 2px solid red; */
}
/* div3 end here */

.newlook-memberrewards-community-image {
  background-image: url(../../media/images/Be-part-of-our-community.jpeg);
  width: 80%;
  margin: auto;
  width: 100%;
  height: 0;
  padding-top: 74%;
  position: relative;
  top: -4rem;
  background-color: #363636;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.newlook-memberrewards-commitment-image {
  background-image: url(../../media/images/Commitment-to-our-people.jpg);
  width: 80%;
  margin: auto;
  width: 100%;
  height: 0;
  padding-top: 74%;
  position: relative;
  top: -4rem;
  background-color: #363636;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.newlook-memberrewards-driving-image {
  background-image: url(../../media/images/Driving-hospitality-experience-together.jpeg);
  width: 80%;
  margin: auto;
  width: 100%;
  height: 0;
  padding-top: 74%;
  position: relative;
  top: -4rem;
  background-color: #363636;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* div7 start here. */
.newlook-careers-hero-bar-wrapper {
  box-shadow: 0 0 8px #bbb;
  padding: 20px;
}

.newlook-careers-hero-bar-item .m4b-homepage-hero-bar-name {
  font-family: Optima, sans-serif;
}

.m4b-homepage-hero-bar-name {
  font-size: 24px;
  font-family: Optima, sans-serif;
  font-weight: 700;
  line-height: 32px;
  color: #4e4e4e;
}

.m4b-homepage-hero-bar-desc {
  margin-top: 6px;
  font-size: 16px;
  font-family: "Nimbus Sans L", sans-serif;
  font-weight: 300;
  line-height: 19px;
  color: #4e4e4e;
}

.m4b-homepage-hero-bar-link {
  min-width: 157px;
  padding: 16px 30px;
  display: inline-block;
  font-size: 12px;
  font-family: "Nimbus Sans L", sans-serif;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.5px;
  background-color: #9f7d23;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
}

.m4b-homepage-hero-bar-link:hover {
  background-color: #c9a43d;
}

.newlook-careers-hero-bar-wrapper {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 50px;
}

/*  div7 end here  */

.logo-region {
  display: flex;
  justify-content: center;
}
/* div8 end    */


.career-font {
  text-align: center;
  background-color: #f7f4ee;
  padding-top: 80px;
  padding-bottom: 80px;
  /* color: #fff; */
}

.career-font .font- {
  font-size: 24px;
}
.career-font .font-semibold {
  font-size: 48px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.career-font .font-normal {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 30px;
}

.career-font .career-btn {
  font-size: 14px;
  padding: 16px 30px;
  font-weight: 400;
  background-color: #9f7d23;
  color: #fff;
  border-radius: 4px;
  /* margin-top: 30px; */
  text-transform: uppercase;
  /* border: 2px solid red; */
}
.career-font .career-btn:hover {
  background-color: #c9a43d;
}
.career-font .wb-hotel {
  color: #7e5e06;
}

@media screen and (max-width: 768px) {
  .career-banner {
    width: 100%;
    height: 62vw;
    min-height: 200px;
  }

  .newlook-caption-title h1 {
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    padding: 5px;
  }

  .newlook-memberrewards-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  .rich-text-content {
    width: 93%;
    /* padding-bottom: 40px; */
    margin: auto;

    grid-row-start: 2;
    grid-row-end: 3;
  }
  .newlook-careers-hero-bar-wrapper {
    flex-direction: column;
  }
  .m4b-homepage-hero-bar-link {
    margin-top: 30px;
  }

  .career-font .font- {
    font-size: 20px;
  }
  .career-font .font-semibold {
    font-size: 38px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .career-font .font-normal {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 50px;
    padding-left: 2px;
    padding-right: 2px;
  }
}
