.card2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  border: 2px solid rgb(215, 240, 234);
}

.title {
  color: grey;
  font-size: 18px;
}

button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

button:hover,
a:hover {
  opacity: 0.7;
}

.card-container-about-us {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  grid-row-gap: 30px;
  width: 80%;
  margin: auto;
  margin-bottom: 4rem;
}

@media screen and (max-width: 768px) {
  .card-container-about-us {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 560px) {
  .card-container-about-us {
    grid-template-columns: repeat(1, 1fr);
  }
}
