.search-hotels {
  display: flex;
  flex-wrap: wrap;
  // grid-template-areas:
  // "date rooms btn ";

  .input {
    grid-area: "input";
    height: 6rem;
    background-color: $color-light;
    padding: 0 13rem 0 2rem;
    flex: 2 0 auto;
    border: 1px solid rgba($color-grey-5, 0.2);
    position: relative;
    border-radius: 5px 0 0 5px;

    input {
      background-color: transparent;
      border: none;
      outline: none;
      width: 100%;
      height: 100%;
      font-family: inherit;
      color: inherit;
      font-size: 1.5rem;
    }

    .near-me {
      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      padding: 0.5rem 1rem;
      background-color: rgba($color-grey-2, 1);
      align-items: center;
      border-radius: 20px;
      user-select: none;
      cursor: pointer;
      svg {
        font-size: 1.5rem;
        margin-right: 0.5rem;
      }
    }

    .list {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: $color-light;
      box-shadow: $shadow-5;
      z-index: 1;

      .item {
        padding: 1rem;
        border-radius: 0 0 5px 5px;
        cursor: pointer;
        &:hover {
          background-color: rgba($color-grey-2, 0.5);
        }
      }
    }
  }
  .date {
    grid-area: "date";
    flex: 1 0 auto;
    background-color: $color-light;
    padding: 0 2rem;
    @include flexCenter;
    border: 1px solid rgba($color-grey-5, 0.2);
    position: relative;
    user-select: none;

    .date-btn {
      width: 100%;
      height: 100%;
      @include flexCenter;
      cursor: pointer;
    }
    .bg-tr {
      position: fixed;
      inset: 0;
      z-index: 1;
    }
    .calendar-range {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 998;
      box-shadow: $shadow-5;
    }
  }
  .rooms {
    grid-area: "rooms";
    flex: 2 0 100px;
    background-color: $color-light;
    @include flexCenter;
    user-select: none;
    border: 1px solid rgba($color-grey-5, 0.2);
    position: relative;

    .rooms-btn {
      width: 100%;
      height: 100%;
      @include flexCenter;
      cursor: pointer;
    }

    .bg-tr {
      position: fixed;
      inset: 0;
      z-index: 1;
    }

    .rooms-card {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      padding: 2rem;
      height: 46vh;
      overflow: auto;
      background-color: $color-light;
      border-radius: 0 0 5px 5px;
      box-shadow: $shadow-5;
      cursor: default;
      z-index: 998;
      min-width: 270px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        // padding: 0 2rem;
      }
      .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        position: relative;
        .child_div {
          display: flex;
          flex-direction: column;
          .child_age {
            opacity: 0.7;
            font-size: x-small;
          }
        }
        .actions {
          display: flex;
          align-items: center;
          margin-top: 1rem;
          .btn {
            width: 3rem;
            height: 3rem;
            border-radius: 5px;
          }
          .no {
            margin: 0 1rem;
            font-size: 1.5rem;
          }
        }
      }

      .room-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        .btn {
          height: 3rem;
          border-radius: 5px;
          &:first-child {
            margin-right: 1rem;
          }
          &:last-child {
            margin-left: 1rem;
          }
        }
        .btn.btn-disabled {
          background-color: rgba($color-secondary-2, 0.5);
          border-color: rgba($color-secondary-2, 0.5);
          cursor: not-allowed;
        }
      }
    }
  }
  .btn {
    grid-area: "btn";
    flex: 2 0 3rem;
    font-size: 14px;
    background-color: $color-secondary-2;
    @include flexCenter;
    color: $color-light;
    cursor: pointer;
    user-select: none;
    border-radius: 0 5px 5px 0;
    transition: $transition-1;
    border: 1px solid $color-secondary-2;

    &:hover {
      background-color: $color-secondary-3;
      border: 1px solid $color-secondary-3;
    }
  }
}

@include respond(tab-port) {
  .search-hotels {
    width: 100%;
    .input {
      flex: 1 0 100%;
    }
    .date {
      flex: 1 1 50%;
      padding: 2rem;
    }
    .rooms {
      flex: 1 1 50%;
    }
    .btn {
      padding: 1.1rem;
      flex: 1 1 100%;
      font-size: 15px;
    }
  }
  // .search-hotels {
  //   width: 100%;
  //   // display: block;
  //   .input {
  //     border-radius: 5px;
  //     margin-bottom: 2rem;
  //   }

  //   .date,
  //   .rooms {
  //     border-radius: 5px;
  //     margin-bottom: 2rem;
  //     .date-btn,
  //     .rooms-btn {
  //       height: 6rem;
  //     }
  //   }

  //   .btn {
  //     height: 6rem;
  //     border-radius: 5px;
  //   }
  // }
}