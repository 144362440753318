* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-primary;
  background-color: $color-background-1;
  color: $color-dark;
  transition: $transition-1;
}

html {
  font-size: 62.5%;

  @include respond(tab-land) {
    font-size: 56.25%;
  }

  @include respond(tab-port) {
    font-size: 50%;
  }

  @include respond(big-desktop) {
    font-size: 75%;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.rdrDefinedRangesWrapper {
  display: none;
}
