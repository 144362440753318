h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.4rem;
}

p {
  font-size: 1.4rem;
}
