.search-results-card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 3rem 0;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 2px solid rgba($color-grey-3, 0.5);
  }
  .images {
    display: flex;
    flex: 1;
    margin-right: 3rem;
    .main-img {
      width: 40rem;
      height: 25rem;
      margin-right: 0.2rem;
      cursor: pointer;
      position: relative;
      background-color: rgba($color-grey-3, 0.5);
      border-radius: 5px;

      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 4rem;
        height: 4rem;
        background-color: rgba($color-dark, 0.75);
        color: $color-light;
        border-radius: 50%;
        @include flexCenter;
        transition: $transition-1;
        z-index: 1;
        svg {
          font-size: 1.5rem;
        }
        &:hover {
          background-color: rgba($color-dark, 1);
        }
      }
      .prev {
        left: 1rem;
      }
      .next {
        right: 1rem;
      }

      .badge {
        position: absolute;
        top: 2rem;
        left: -1rem;
        padding: 0.5rem 1rem;
        background-color: $color-dark;
        color: $color-light;
        display: flex;
        align-items: center;
        border-radius: 3px;
        svg {
          font-size: 1.3rem;
          margin-right: 0.5rem;
          color: $color-primary-1;
        }
        p {
          font-size: 1rem;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: $transition-1;
        border-radius: 5px;
        animation: fadeIn 0.3s forwards;
      }
    }

    .side-img {
      .img {
        width: 8rem;
        height: 6rem;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }
  }

  .desc {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .name {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
    .loc {
      svg {
        color: $color-danger-2;
      }
    }
    .ratings {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .badge {
        padding: 0.4rem 0.8rem;
        background-color: $color-primary-2;
        border-radius: 5px;
        color: $color-light;
        margin-right: 2rem;
        font-size: 1.2rem;

        svg {
          margin-left: 0.25rem;
        }
      }
      .rating-amount {
        margin-right: 2rem;
        font-size: 1.2rem;
      }
      .rating-feedback {
        font-size: 1.2rem;
      }
    }

    .facilities {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .tab {
        margin-right: 2rem;
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        cursor: pointer;
        user-select: none;
        .img {
          margin-right: 1rem;
          width: 3rem;
          height: 3rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .more {
        color: $color-secondary-3;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .member {
      .button-primary-comp {
        padding: 1rem;
        width: fit-content;
        background-color: $color-primary-2;
        p {
          font-size: 1rem;
        }
        svg {
          font-size: 1.3rem;
          margin-right: 0.5rem;
        }

        &:hover {
          background-color: $color-primary-2;
        }
      }
    }
    .bottom {
      display: flex;
      // justify-content: space-between;
      .left-sec {
        text-align: left;
        .price {
          display: flex;
          align-items: center;
          h1 {
            margin-right: 2rem;
            font-size: 2rem;
          }
          p {
            margin-right: 2rem;
            text-decoration: line-through;
            color: $color-grey-5;
          }
          h3 {
            color: $color-success-2;
          }
        }
        .bottom-desc {
          color: $color-grey-5;
        }
      }
      .right-sec{
        text-align: right;
        margin-left: 3rem;
      }
    }
  }
}
