
.edit-card-user-profile{
    width: 50%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
 
}

 .user-profie h1 {
    font-size: 2.5rem;
    padding-top: 20px;
    padding-bottom: 20px;
}
.user-form span{
    font-size: 16px;
    font-weight: 600;
}


.edit-card-user-profile > *{
    flex: 1 1 auto;
    padding: 30px;
    margin: 50px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.user-form input {
    border: hidden;
    height: 5rem;
    width: 100%;
    font-size: 20px;
    padding-left: 5px;
    margin-bottom: 20px;
    margin-top: 10px;
}


@media screen and (max-width: 768px) {
    .edit-card-user-profile > *{
        margin: 10px;
    }
    .edit-card-user-profile{
        width: 60%;
        margin: auto;
    }

}

@media screen and (max-width: 468px) {
    .edit-card-user-profile > *{
        margin: 10px;
    }
    .edit-card-user-profile{
        width: 95%;
        margin: auto;
    }

    .user-form input {
        font-size: 16px;
        padding-left: 5px;
        margin-bottom: 6px;
        margin-top: 4px;
    }

    .user-form span{
        font-size: 14px;
        font-weight: 600;
    }
    
}