* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Optima, sans-serif;
  }

.main-login-trvl {
    margin: 45px auto auto;
    width: 95%;
}
.login-sign-main-p{
    /* border-bottom: 5xp solid rgb(191, 67, 67); */
    /* border-bottom: #301a1a; */
}

.login-sign-p{
    font-size: 16px;
    margin-bottom: 10px;
}

.both-btn-trvl-crpt{
    display: flex;
   justify-content: space-between;

}

.btn-travel-agent{
    color: black;
    background-color: rgba(0, 0, 0, 0.12);
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 4px;
    padding: 12px;

    
}

.btn-travel-agent:hover{
    color: #fff;
    /* color: black; */
    background-color: #1976d2;
    /* background-color: #060f91c5; */
}

.css-1y46ui3 {
  
    font-size: 0rem !important;
   
}

.css-1bo0huo {
    margin-top: 30px;
}
.login-content-div h2{
    font-size: 18px;
    font-weight: 500;
} 
.login-content-div h1{
    margin: 10px 0;
    font-size: 30px;
    font-weight: 600;
} 
.login-content-div p{
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 20px;
} 



@media screen and (max-width: 768px) {
    .main-login-trvl{
        width: 65%;
        margin: auto;
        margin-top: 15px;
    }
}

@media screen and (max-width: 568px) {
    .main-login-trvl{
        width: 95%;
        margin: auto;
        margin-top: 0px;
        padding: 0px;
    }

    .login-sign-p {
        font-size: 12px;
        margin-bottom: 2px;
    }

    .btn-travel-agent{
        font-size: 12px;
    }
}
